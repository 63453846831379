<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header></template>
      <template v-slot:actions>
        <a-btn-refresh @click="$refs.table.updateData()" />
        <a-btn-add :icon="'far fa-plus-square'" :title="'Создать'" v-if="getAccess('documents.estimate.create')" @click="createNew()" />
      </template>
      <portal to="v-main">
        <s-document-head v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :config="configHead" :title="m.title" :id="idEdit" />
      </portal>
      <a-table-f-api
        ref="table"
        :api="url"
        :model="getModelList(m)"
        :useQuery="false"
        :defaults="defaults"
        :selectedRows="selectedRows"
        @click="
          counter += 1;
          onDetectClick($event);
        "
      >
      </a-table-f-api>
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import { getAccess, genModel, doubleClickDetect, keyDetect } from "@/components/mixings";

export default {
  mixins: [getAccess, genModel, doubleClickDetect, keyDetect],
  components: {
    ViewItem: () => import("./views/estimateView"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      idItemShow: 0,
      title: "",
      m: this.$store.getters["config/get"].models.documentEstimate,
      m2: {
        title: "Сметы",
        routeName: "estimate_view",
        accessName: "estimate",
        operationsCash: [],
        api: "/accounting/doc/estimate",
        accDocName: "AccountingDocEstimate",
        statuses: [
          { value: null, text: "черновик", next: [2], action: "Вернуть" },
          { value: 0, text: "черновик", next: [2], action: "Вернуть" },
          { value: 1, text: "На проверке", next: [0, 3], action: "Отправить" },
          { value: 2, text: "Отправлено", next: [], action: "Отправить" },
          { value: 3, text: "Отказан", next: [2], action: "Отказать" },
        ],
        types: [
          { value: 1, text: "Лид (полная)" },
          { value: 3, text: "Лид (краткая)" },
          { value: 2, text: "Клиент (полная)" },
          { value: 4, text: "Клиент (краткая)" },
        ],
        items: [
          { value: "plannig", text: "Планировочное решение" },
          { value: "drawings", text: "Рабочие чертежи" },
          { value: "visualization", text: "3D-визуализация" },
          { value: "specification", text: "Спецификация мебели" },
        ],
        list: [
          { name: "id", title: "id", type: "number", sortable: true, width: 30, hidden: true },
          { name: "code_doc", title: "Номер", type: "string", sortable: true, width: 50 },
          { name: "date_doc", title: "Дата", type: "datetime", sortable: true, width: 120 },
          { name: "type", title: "Тип", type: "select", sourceList: "types", sortable: true, width: 50, access: { role: [-1, -10] } },
          { name: "estimate_name", title: "Название", type: "string", sortable: false, width: 120 },
          { name: "lead_id", title: "Лид", type: "selectapi", api: "mechti/leads/select", sortable: true, width: 150, access: { role: [-1, -10] } },
          { name: "client_id", title: "Клиент", type: "select", dir: "client", sortable: true, width: 150, access: { role: [-1, -10] } },
          {
            name: "object_id",
            title: "Объект",
            type: "select",
            dir: "object",
            filter1: { type: "selectapi", api: "/mechti/objects/select" },
            sortable: true,
            width: 150,
          },
          { name: "object_class", title: "Класс", type: "select", options: "rooms_classes", sortable: true, width: 50 },
          { name: "object_square", title: "Площадь объекта", type: "number", sortable: true, width: 80 },
          {
            name: "createdby_id",
            title: "Автор",
            type: "select",
            dir: "user",
            filter: { type: "select", name: "createdby_id", dir: "user" },
            sortable: true,
            width: 150,
            access: { role: [-1, -10] },
          },
          { name: "status", title: "Статус", type: "select", sourceList: "statuses", sortable: true, width: 150, access: { role: [-1, -10] } },
          { name: "valid_date", title: "Актуален до", type: "datetime", sortable: true, width: 120 },
          { name: "value", title: "Сумма ", type: "number", sortable: true, width: 80 },
        ],
        viewForm1: { fields: "createdby_id,date_doc,estimate_name,lead_id,object_square,data.ap_level,data.ap_rooms" },
        viewForm2: { fields: "value,description,comments" },
        form: [
          {
            name: "type",
            title: "Тип",
            type: "select",
            sourceList: "types",
            sortable: true,
            width: 50,
            hint: 'Выберите тип "Клиент", если клиент уже заведен в системе и смета будет экспортироваться в объект для создания графика работ и чек-листа.',
          },
          { name: "code_doc", title: "Номер Документа", type: "string", hiddenCreate: true },
          { name: "date_doc", title: "Дата документа", type: "datestring", hiddenCreate: true },
          { name: "estimate_name", title: "Название", type: "string" },
          { name: "valid_date", title: "Актуален до", type: "datestring", hint: "Укажите дату, если необходимо ограничить время действия предложения и сметы для него." },
          { name: "client_id", title: "Клиент", type: "select", dir: "client", autocomplete: true, hint: "Клиент должен быть заведен в разделе Клиенты." },
          { name: "object_id", title: "Объект", type: "select", dir: "object", autocomplete: true, hint: "Связь с объектом" },
          {
            name: "lead_id",
            title: "Лид",
            type: "selectapi",
            api_: "mechti/leads/select",
            api: { type: "post", api: "/report_sql", data: { name: "getLeadsList", params: { type: 0 } } },
          },
          {
            name: "object_class",
            title: "Класс",
            type: "select",
            options: "rooms_classes",
            hint: 'Класс объекта будет по умолчанию назначаться комнатам на объекте. Класс отдельной комнаты можно изменить в табе "Комнаты".',
          },
          { name: "object_square", title: "Площадь объекта", type: "number", validator: ["req"], hint: "Площадь в квадратных метрах" },
          { name: "status", title: "Статус", validator: ["req"], default: 0, hidden: true },
          { name: "comments", title: "Комментарий", type: "text" },
          { name: "createdby_id", title: "Автор", type: "select", dir: "user", hiddenCreate: true, roleShow: [-1, -10] },
          {
            name: "template_name",
            title: "Тип коммерческого предлложения",
            type: "select",
            options: "template_promo",
            hint: "Необязательное поле. КП формируется в HTML формате, содержит индивидуальную ссылку на регистрацию пользователя в качестве клиента.",
          },
          { name: "data.services", title: "Услуги", type: "select", sourceList: "items", chips: true },
          { name: "data.total_price", title: "Общая стоимость", type: "number" },
          {
            name: "data.ap_level",
            title: "Уровней",
            type: "number",
            hint: 'Укажите количество уровней. Затем укажите название уровней в поле "Настройка уровней" ниже.',
          },
          {
            name: "data.ap_rooms",
            title: "Комнат",
            type: "number",
            hint: 'Количество комнат. В табе "Комнаты" необходимо создать комнаты по количеству, указанному в данном поле.',
          },
          {
            name: "data.levels",
            title: "Настройка уровней",
            type: "array",
            accordion: true,
            model: [
              { name: "name", title: "Название уровня", type: "string" },
              { name: "description", title: "Описание", type: "text", rows: 2 },
            ],
          },
        ],
        rooms: {
          typeModel: "main",
          config: {
            default: {
              fields: "data.level,data.room_number,name,room_type,room_class,room_square,wall_height,wall_length",
              fieldsRO: "",
              fieldsForm: "data.level#2,data.room_number#2,name#5,room_type#3,room_class#3,room_square#3,wall_height#3,wall_length#3",
            },
          },
          list: [
            { name: "actions", width: 40, statusHide: 2 },
            { name: "id", title: "id", type: "number", sortable: true, width: 55 },
            { name: "data.level", title: "Уровень", type: "string", sortable: true, width: 60 },
            { name: "data.room_number", title: "Номер", type: "string", sortable: true, width: 60, style: "white-space: normal;" },
            { name: "name", title: "Наименование", type: "string", sortable: true, width: 300, style: "white-space: normal;" },
            { name: "room_type", title: "Тип", type: "select", options: "room_type", sortable: true, width: 80 },
            { name: "room_class", title: "Класс", type: "select", options: "rooms_classes", sortable: true, width: 80 },
            { name: "room_square", title: "Площадь", type: "number", sortable: true, width: 60 },
            { name: "wall_height", title: "H-стен", type: "number", sortable: true, width: 60 },
            { name: "wall_length", title: "L-стен", type: "number", sortable: true, width: 60 },
            { name: "comments", title: "Комментарий", type: "text" },
          ],
          form: [
            { name: "data.level", title: "Уровень", type: "select", options: [1], default: 1, validator: ["req"] },
            { name: "data.room_number", title: "Номер", type: "number", validator: ["req"] },
            { name: "name", title: "Наименование", type: "string", validator: ["req"] },
            { name: "room_type", title: "Тип", type: "select", options: "room_type", validator: ["req"] },
            { name: "room_class", title: "Класс", type: "select", options: "rooms_classes", validator: ["req"] },
            { name: "room_square", title: "Площадь", type: "number", validator: ["req"] },
            { name: "wall_height", title: "H-стен", type: "number" },
            { name: "wall_length", title: "L-стен", type: "number" },
            { name: "comments", title: "Комментарий", type: "text" },
          ],
        },
        goods: {
          typeModel: "main",
          list: [
            { name: "actions", width: 40, statusHide: 2 },
            { name: "id", title: "id", type: "number", sortable: true, width: 55 },
            {
              name: "room_id",
              title: "Комната",
              type: "selectapi",
              api: {
                type: "post",
                api: "/report_sql",
                data: {
                  name: "getEstimateRoomList",
                  params: { parent_id: "(function(d) {return d.parent_id;})", id: "(function(d) {return d.room_id ? d.room_id : -2 ;})" },
                },
              },
              itemField: { value: "id", text: "name" },
            },
            { name: "data.name", title: "Наименование", type: "string", hidden: true },
            {
              name: "good_id",
              title: "Наименование",
              type: "selectapi",
              api: "mechti/goods_type",
              itemField: { text: "name", value: "id" },
              sortable: true,
              width: 300,
              style: "white-space: normal;",
            },
            { name: "goods_class", title: "Класс", type: "select", options: "rooms_classes" },
            { name: "price", title: "Цена", type: "number", sortable: true, width: 50 },
            { name: "amount", title: "Кол-во", type: "number", sortable: true, width: 50 },
            { name: "value", title: "Стоимость", type: "number", sortable: true, width: 80 },
            { name: "comments", title: "Коментарий", type: "text" },
          ],
          form: [
            {
              name: "room_id",
              title: "Комната",
              type: "selectroom",
              api: {
                type: "post",
                api: "/report_sql",
                data: { name: "getEstimateRoomList", params: { parent_id: "(function(d) {return d.parent_id;})", id: "(function(d) {return -1 ;})" } },
              },
            },
            {
              name: "good_id",
              title: "Тип товара",
              type: "selectapi",
              api: "/mechti/goods_type",
              filters: { status: 1 },
              sort: { key: "name", order: "ASC" },
              itemField: { text: "name", value: "id" },
              check: true,
            },
            {
              name: "category_id",
              title: "Категория товара",
              type: "select",
              dir: "category",
              api: "accounting/dir/service_category/select",
              filters: {
                type: 2,
                OR: [
                  { field: "status", condition: "<>", value: 2 },
                  { field: "status", condition: "IS", value: null },
                ],
              },
              readonly: true,
              check: true,
            },
            { name: "goods_class", title: "Класс", type: "select", options: "rooms_classes", size: 3 },
            { name: "data.price_comfort", title: "Стоп-цена(комфорт)", type: "number", readonly: true, size: 3 },
            { name: "data.price_business", title: "Стоп-цена(бизнес)", type: "number", readonly: true, size: 3 },
            { name: "data.price_premium", title: "Стоп-цена(премиум)", type: "number", readonly: true, size: 3 },
            { name: "amount", title: "Кол-во", type: "number", default: 1, size: 4, validator: ["req"] },
            { name: "price", title: "Цена", type: "number", size: 4 },
            { name: "value", title: "Стоимость", type: "number", formula: "(function(d) {return d.price*d.amount ;})", readonly: true, size: 4 },
            { name: "comments", title: "Комментарий", type: "text" },
          ],
        },
        works: {
          typeModel: "main",
          config1: {
            default: {
              fields: "data.level,data.room_number,name,room_type,room_class,room_square,wall_height,wall_length",
              fieldsRO: "",
              fieldsForm: "data.level#2,data.room_number#2,name#5,room_type#3,room_class#3,room_square#3,wall_height#3,wall_length#3",
            },
          },
          list: [
            { name: "actions", width: 40, statusHide: 2 },
            { name: "id", title: "id", type: "number", sortable: true, width: 55 },
            {
              name: "room_id",
              title: "Комната",
              type: "selectapi",
              api: {
                type: "post",
                api: "/report_sql",
                data: {
                  name: "getEstimateRoomList",
                  params: { parent_id: "(function(d) {return d.parent_id;})", id: "(function(d) {return d.room_id ? d.room_id : -2 ;})" },
                },
              },
              itemField: { value: "id", text: "name" },
              formula1: '((d)=>{console.log("get room name", d);return d.data_table[0].find((r)=>r.id==value)?.name})',
            },
            {
              name: "service_id",
              title: "Наименование",
              type: "select",
              dir: "service",
              dirFilter1: { category_id: [27, 28, 29] },
              sortable: true,
              width: 300,
              style: "white-space: normal;",
            },
            { name: "works_class", title: "Класс", type: "select", options: "rooms_classes", size: 3 },
            { name: "price", title: "Цена", type: "number", sortable: true, width: 50 },
            { name: "amount", title: "Коэф.", type: "number", sortable: true, width: 50 },
            { name: "value", title: "Стоимость", type: "number", sortable: true, width: 80 },
            { name: "comments", title: "Коментарий", type: "text" },
          ],
          form: [
            {
              name: "room_id",
              title: "Комната",
              type: "selectapi",
              api: {
                type: "post",
                api: "/report_sql",
                data: {
                  name: "getEstimateRoomList",
                  params: { parent_id: "(function(d) {return d.parent_id;})", id: "(function(d) {return d.room_id && false ? d.room_id : -1 ;})" },
                },
              },
              itemField: { value: "id", text: "name" },
              formula1: '((d)=>{console.log("get room name", d);return d.data_table[0].find((r)=>r.id==value)?.name})',
            },
            {
              name: "service_id",
              title: "Наименование",
              type: "selectapi",
              filters: { category_id: [28], status: 1 },
              api: "accounting/dir/service",
              itemField: { text: "name", value: "id" },
              dir: "service",
              dirFilter: { category_id: [28] },
            },
            { name: "works_class", title: "Класс", type: "select", options: "rooms_classes", size: 6 },
            { name: "data.square", title: "Площадь", type: "number", readonly: true, size: 6 },
            { name: "data.price_comfort", title: "Стоп-цена(комфорт)", type: "number", readonly: true, size: 4 },
            { name: "data.price_business", title: "Стоп-цена(бизнес)", type: "number", readonly: true, size: 4 },
            { name: "data.price_premium", title: "Стоп-цена(премиум)", type: "number", readonly: true, size: 4 },
            { name: "price", title: "Цена", type: "number", size: 4 },
            { name: "amount", title: "Коэффициент", type: "number", default: 1, size: 4 },
            { name: "value", title: "Стоимость", type: "number", formula: "(function(d) {return d.price*d.amount*(d?.data?.square || 1) ;})", readonly: true, size: 4 },
            { name: "comments", title: "Коментарий", type: "text" },
          ],
        },
        config: {
          1: {
            fields: "type,lead_id,object_square,comments,estimate_name,valid_date,template_name,status,object_class",
            fieldsRO: "",
            fieldsForm: "type#4,estimate_name#4,lead_id,object_class,object_square,valid_date,comments,template_name",
          },
          2: {
            fields:
              "type,client_id,object_id,comments,estimate_name,valid_date,template_name,status,object_class,object_square,data.ap_level,data.ap_rooms,data.rooms,data.levels",
            fieldsRO: "data.ap_level",
            fieldsForm:
              "type#4,client_id#8,object_id#8,object_class#4,object_square#4,data.ap_level#4,data.ap_rooms#4,valid_date,data.levels,data.rooms,comments,template_name",
          },
          3: {
            fields: "type,lead_id,object_square,comments,estimate_name,valid_date,template_name,status,object_class,data.services,data.total_price",
            fieldsRO: "",
            fieldsForm: "type#4,lead_id#8,data.services,data.total_price#4,object_class#4,object_square#4,valid_date,comments,template_name",
          },
          watch: "type",
          default: { fields: "type,status", fieldsRO: "", fieldsForm: "type" },
        },
      },

      infoData: null,
      infoModel: null,
    };
  },
  created() {
    this.$root.title = "Сметы";
  },
  computed: {
    url() {
      return this.m.api;
    },
    defaults() {
      const d = {
        sort: { key: "date_doc", order: "DESC" },
        filters: this.getAccess("documents.estimate.viewAll") ? {} : { createdby_id: this.$root.profile.id },
        paramName: "documentEstimate",
      };
      if (this.$root.profile.role <= 20) {
        d.filters.status = { condition: ">", value: 0 };
      }
      return d;
    },
    permit() {
      return this.getAccess("menu.documentEstimate");
    },
    configHead() {
      return this.m.config.default || {};
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },

    onSingleClick(d) {},
    onDoubleClick(d) {
      this.idItemShow = d.row.id;
      return;
      this.onClickRow(d);
    },
    onClickRow(d) {
      //  this.$router.push({ name: "objectBuy_view", params: { id: d.row.id } });

      this.showEditDialogFun(d.row.id);
    },
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
